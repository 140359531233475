import React, { useEffect, useState } from 'react';
import { Layout, Menu, Select } from "antd";
import { Link } from "react-router-dom";
import Icon, { HomeOutlined, PictureOutlined, PoundCircleOutlined, MessageOutlined, BankOutlined,RocketOutlined } from '@ant-design/icons';
import GroupSVG from '../../assets/jobs-icon.svg';
import ConnectionSVG from '../../assets/socials.svg';
import { CountryDropdown } from 'react-country-region-selector';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';

const { Sider } = Layout;

const Sidemenu = () => {
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    countryFetch();
  }, []);
  const countryFetch = async () => {
    const response = await APIUtils.apiCall('GET', WebLinks.CountryList);
    if (response.resultObj?.statusCode === "2000") {

      setCountryList(response.resultObj.data);
    }
  };

  const LocationChanged = async (value) => {
    await Cookies.set(constants.COOKIE_SELECTED_COUNTRY, value);
    let countryDetails = countryList.find((item) => item.value === value);
    //reload the page to refresh buyer details on the open menu item eg. application report list
    window.location.reload();
  };

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      theme='light'
      className='lg:!bg-transparent'
      width={255}
    >
      <div className='px-4 py-3 mb-4 bg-white border-2 border-gray-400 drop-shadow-lg'>
        <div><label>Logged in as:</label></div>
        <p className='mb-2.5'><strong>admin@applygateway.com</strong></p>
        <div><label>Location:</label></div>
        {/* <CountryDropdown
          priorityOptions={["GB", "US", "CA"]}
          className='w-full h-8 rounded-md border border-black'
        /> */}
        <Select
          showSearch
          placeholder="Select Country"
          defaultValue={parseInt(Cookies.get(constants.COOKIE_SELECTED_COUNTRY))}
          className='w-full rounded-md border border-black'
          onChange={(e) => LocationChanged(e)}
        >
          {countryList.map((country) => (
            <Select.Option key={country.countryCode} value={country.countryCode}>
              {country.county}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['0']} className="!border-2 !border-gray-400 drop-shadow-lg">
        <Menu.Item key="0" icon={<HomeOutlined className='!text-lg' />}>
          <Link to="/">
            Home
          </Link>
        </Menu.Item>
        <Menu.Item key="1" icon={<PoundCircleOutlined className='!text-lg' />}>
          <Link to="/buyer">
            Buyer
          </Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<Icon component={() => (<img src={ConnectionSVG} alt='icon' />)} />}>
          <Link to="/supplier">
            Supplier
          </Link>
        </Menu.Item>
        <Menu.Item key="7" icon={<RocketOutlined className='!text-lg'/>}>
          <Link to="/boost-tenants">
           Boost Tenants
          </Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<PictureOutlined className='!text-lg' />}>
          <Link to="/images">
            Images
          </Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<PictureOutlined className='!text-lg' />}>
          <Link to="/indeed">
          Indeed Data
          </Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<Icon component={() => (<img src={GroupSVG} alt='icon' />)} />}>
          <Link to="/users">
            Users
          </Link>
        </Menu.Item>
        <Menu.Item key="6" icon={<MessageOutlined className='!text-lg' />} disabled>
          <Link to="/">
            Blogs
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="6" icon={<BankOutlined className='!text-lg' />}>
          <Link to="/companies">
            {constants.Companies}
          </Link>
        </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default Sidemenu;
